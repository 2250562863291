import React, { useEffect, useState, useRef } from "react";
import { Card, Spin, Button } from "antd";
import { CheckCircleFilled, ExclamationCircleFilled, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { API_URL } from "../utils/jobApi";
import { getAccessToken } from "../utils/auth";

const OCRImagePreview = ({
  imageUrl,
  jobName,
  image_index,
  isVerified,
  setIsVerified,
}) => {
  const [loading, setLoading] = useState(true);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const fetchImageVerification = async () => {
      try {
        const response = await fetch(
          `${API_URL}/jobs/image_verification/${image_index}?job_name=${encodeURIComponent(
            jobName
          )}`,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${getAccessToken()}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setIsVerified(data.is_verified);
      } catch (error) {
        console.error("Error fetching image verification:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchImageVerification();
  }, [image_index, jobName]);

  // Handle image load to get natural dimensions
  const handleImageLoad = () => {
    if (imageRef.current) {
      setImageSize({
        width: imageRef.current.naturalWidth,
        height: imageRef.current.naturalHeight
      });
    }
  };

  const handleMouseDown = (e) => {
    if (scale > 1) {  // Only allow dragging when zoomed in
      e.preventDefault();
      setIsDragging(true);
      setDragStart({
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      });
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging && scale > 1) {
      const newX = e.clientX - dragStart.x;
      const newY = e.clientY - dragStart.y;
      
      // Calculate boundaries
      const containerRect = containerRef.current.getBoundingClientRect();
      const maxX = (containerRect.width * (scale - 1)) / 2;
      const maxY = (containerRect.height * (scale - 1)) / 2;

      // Constrain position within boundaries
      setPosition({
        x: Math.max(-maxX, Math.min(maxX, newX)),
        y: Math.max(-maxY, Math.min(maxY, newY)),
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleZoomIn = () => {
    setScale(prev => {
      const newScale = Math.min(prev + 0.5, 3);
      if (newScale === 1) {
        setPosition({ x: 0, y: 0 }); // Reset position when returning to original size
      }
      return newScale;
    });
  };

  const handleZoomOut = () => {
    setScale(prev => {
      const newScale = Math.max(prev - 0.5, 1);
      if (newScale === 1) {
        setPosition({ x: 0, y: 0 }); // Reset position when returning to original size
      }
      return newScale;
    });
  };

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <Card bordered={false} style={{ position: "relative" }}>
      <div
        ref={containerRef}
        style={{
          overflow: "hidden",
          height: "500px",
          width: "100%",
          position: "relative",
          cursor: scale > 1 ? (isDragging ? "grabbing" : "grab") : "default",
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <img
          ref={imageRef}
          src={imageUrl}
          alt="OCR Sample"
          onLoad={handleImageLoad}
          style={{
            transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
            transformOrigin: "center",
            transition: isDragging ? "none" : "transform 0.3s",
            position: "absolute",
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
            left: "50%",
            top: "50%",
            marginLeft: "-50%",
            marginTop: "-50%",
          }}
          draggable={false}
        />
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: 15,
          marginBottom: 10,
          display: "flex",
          justifyContent: "center",
          gap: "10px"
        }}
      >
        <Button 
          icon={<ZoomInOutlined />}
          onClick={handleZoomIn}
          disabled={scale >= 3}
        >
          Zoom In
        </Button>
        <Button 
          icon={<ZoomOutOutlined />}
          onClick={handleZoomOut}
          disabled={scale <= 1}
        >
          Zoom Out
        </Button>
      </div>

      {isVerified === true ? (
        <CheckCircleFilled
          style={{
            position: "absolute",
            top: 0,
            right: 10,
            fontSize: "50px",
            color: "#7CB342",
            zIndex: 1,
          }}
        />
      ) : isVerified === false ? (
        <ExclamationCircleFilled
          style={{
            position: "absolute",
            top: 0,
            right: 10,
            fontSize: "50px",
            color: "#D84315",
            zIndex: 1,
          }}
        />
      ) : null}
    </Card>
  );
};

export default OCRImagePreview;