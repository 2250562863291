// src/components/SelectAgent.js

import React, {useState, useEffect} from "react";
import { Card, List, Avatar, Input, Typography, Spin } from "antd";
import { UserOutlined, SearchOutlined } from "@ant-design/icons";
import { fetchAgentsData } from "../utils/assignJobsApi";

const { Text } = Typography;

const SelectAgent = ({
  agentsData,
  selectedAgentId,
  handleAgentSelect,
  searchAgentText,
  setSearchAgentText,
  loading,
}) => {
  const [currentPage2, setCurrentPage2] = useState(1);
  const [pageSize2, setPageSize2] = useState(10); // Default page size

  useEffect(() => {
    fetchAgentsData(pageSize2, currentPage2);
  }, [pageSize2, currentPage2]);

  const filteredAgents = agentsData.filter((agent) =>
    agent.name.toLowerCase().includes(searchAgentText.toLowerCase())
  );

  return (
    <Card title="Select Agent" style={{ height: "100%" }}>
      <Input
        placeholder="Search agents"
        value={searchAgentText}
        onChange={(e) => setSearchAgentText(e.target.value)}
        style={{ marginBottom: "8px" }}
        allowClear
        prefix={<SearchOutlined />}
      />
      <Spin spinning={loading}>
        <List
          dataSource={filteredAgents}
          renderItem={(agent) => (
            <List.Item
              onClick={() => handleAgentSelect(agent.id)}
              style={{
                background:
                  selectedAgentId === agent.id ? "#e6f7ff" : "transparent",
                cursor: "pointer",
                padding: "8px 16px",
                borderRadius: "4px",
                marginBottom: "4px",
              }}
            >
              <List.Item.Meta
                avatar={<Avatar icon={<UserOutlined />} />}
                title={<Text strong>{agent.name}</Text>}
              />
            </List.Item>
          )}
          style={{ padding: "10px" }}
          pagination={{
            current: currentPage2,
            pageSize: pageSize2,
            total: filteredAgents.length,
            onChange: (page, size) => {
              setCurrentPage2(page);
              setPageSize2(size);
            },
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20"],
            showTotal: (total) => `Total ${total} agents`,
          }}
        />
      </Spin>
    </Card>
  );
};

export default SelectAgent;
