import React, { useState, useEffect } from "react";
import {
  List,
  Input,
  Popconfirm,
  Button,
  Tooltip,
  Pagination,
  message,
  DatePicker,
  Spin,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ProjectOutlined,
  TableOutlined,
  FileDoneOutlined,
  BookOutlined,
  SortAscendingOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { deleteProject, editProject } from "../utils/project";
import EditProjectModal from "./EditProjectModal";

const { RangePicker } = DatePicker;

const ProjectList = ({
  projects,
  selectedProjectId,
  onSelectProject,
  setProjects,
  populateProjectDetails,
}) => {
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [sortOrder, setSortOrder] = useState("newest"); // Set default to 'newest'

  const [dateRange, setDateRange] = useState([null, null]);
  const [loading, setLoading] = useState(false);
  const pageSize = 10;
  const [isEditProjectModalVisible, setIsEditProjectModalVisible] =
    useState(false);
  const [editProjectId, setEditProjectId] = useState();
  const [editedProject, setEditedProject] = useState({
    name: "",
    description: "",
    type: "",
  });

  // Update sorted projects when projects or sortOrder changes
  useEffect(() => {
    const sorted = [...projects].sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      if (sortOrder === "newest") {
        return dateB - dateA; // Sort by newest first
      }
      return dateA - dateB; // Sort by oldest first
    });
    setSortedProjects(sorted);
  }, [projects, sortOrder]);

  // Filter projects by search text and date range
  const filteredProjects = sortedProjects.filter((project) => {
    const matchesSearch = project.name
      .toLowerCase()
      .includes(searchText.toLowerCase());
    const matchesDateRange = (() => {
      if (!dateRange || !dateRange[0] || !dateRange[1]) return true; // Ignore filter if date range is not set
      const createdAt = new Date(project.created_at); // Convert created_at to Date
      return createdAt >= dateRange[0] && createdAt <= dateRange[1];
    })();
    return matchesSearch && matchesDateRange;
  });

  const handleDeleteProject = async (projectId) => {
    try {
      await deleteProject(projectId);
      setProjects((prevProjects) =>
        prevProjects.filter((project) => project.id !== projectId)
      );
      if (selectedProjectId === projectId) {
        onSelectProject(null);
      }
      message.success("Project deleted successfully");
    } catch (error) {
      message.error("Failed to delete project");
    }
  };

  // Function to get icon based on project type
  const getProjectIcon = (type) => {
    switch (type) {
      case "table":
        return <TableOutlined />;
      case "receipt":
        return <FileDoneOutlined />;
      case "book_notes":
        return <BookOutlined />;
      case "omr":
        return <ProfileOutlined />;
      default:
        return <ProjectOutlined />;
    }
  };

  // Pagination
  const startIndex = (currentPage - 1) * pageSize;
  const paginatedProjects = filteredProjects.slice(
    startIndex,
    startIndex + pageSize
  );

  const handleSortToggle = () => {
    setSortOrder(sortOrder === "newest" ? "oldest" : "newest");
  };

  // Handle Edit project
  const handleEditProject = async () => {
    console.log(
      "populateProjectDetails inside handleEditProject -> ",
      populateProjectDetails
    );
    // if (
    //   !editedProject.name ||
    //   !editedProject.description ||
    //   !editedProject.type
    // ) {
    //   message.error("Please fill in all fields.");
    //   return;
    // }

    try {
      setLoading(true);
      // Call the backend to create a new project
      // const response = await createProject(newProject);
      const response = await editProject(editedProject, editProjectId);

      if (response.id && response.name) {
        // Update the local project list with the returned project data, including the MongoDB ID

        window.location.reload();

        message.success("Project edited successfully!");

        // Clear the form fields after submission
        setEditedProject({ name: "", description: "", type: "" });
      } else {
        message.error("Failed to edit project");
      }
    } catch (error) {
      message.error("An error occurred while editing the project");
      console.log(error);
    } finally {
      setLoading(false);
      setIsEditProjectModalVisible(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 8,
        }}
      >
        <Input.Search
          placeholder="Search Projects"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            setCurrentPage(1); // Reset to the first page when search text changes
          }}
          style={{ marginBottom: 8, marginRight: 10 }}
        />

        <Button onClick={handleSortToggle} style={{ marginBottom: 8 }}>
          <SortAscendingOutlined /> (
          {sortOrder === "newest" ? "Newest" : "Oldest"})
        </Button>
      </div>
      <RangePicker
        placeholder={[
          "Project Creation Start Date",
          "Project Creation End Date",
        ]}
        onChange={(dates) => {
          setDateRange(dates);
          setCurrentPage(1); // Reset to the first page when date range changes
        }}
        style={{ marginRight: 10, width: "100%" }}
      />
      <Spin spinning={loading}>
        <List
          dataSource={paginatedProjects}
          renderItem={(item) => (
            <List.Item
              onClick={() => onSelectProject(item.id)}
              style={{
                cursor: "pointer",
                backgroundColor:
                  item.id === selectedProjectId ? "#e6f7ff" : "transparent",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>
                {getProjectIcon(item.type)} {item.name}
              </span>
              <div>
                <Popconfirm
                  title="Are you sure you want to edit this project?"
                  onConfirm={() => setIsEditProjectModalVisible(true)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Edit Project">
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      onClick={(e) => {
                        setEditProjectId(item.id);
                        return e.stopPropagation();
                      }}
                    />
                  </Tooltip>
                </Popconfirm>
                <Popconfirm
                  title="Are you sure you want to delete this project?"
                  onConfirm={() => handleDeleteProject(item.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Delete Project">
                    <Button
                      type="text"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Tooltip>
                </Popconfirm>
              </div>
            </List.Item>
          )}
        />
      </Spin>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        // pageSizeOptions={[5, 10, 20]}
        total={filteredProjects.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: 16, textAlign: "center" }}
        showSizeChanger={false}
      />
      {/* Edit Project Modal */}
      <EditProjectModal
        isVisible={isEditProjectModalVisible}
        onCancel={() => setIsEditProjectModalVisible(false)}
        onFinish={handleEditProject}
        form={null}
        projects={editedProject}
        setProjects={setEditedProject}
        populateProjectDetails={populateProjectDetails}
        // loading={loading}
      />
    </>
  );
};

export default ProjectList;
