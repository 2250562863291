import React, { useState, useEffect } from "react";
import { Select, Button, Input, Space, Form, Tooltip, Popconfirm, Row, Col } from "antd";
import FormInputs from "./FormInputs";
import { DeleteOutlined } from "@ant-design/icons";
const { Option } = Select;

const CreateCustomSchema = ({ initialSchema, readOnly = false, onSave, isNewSchema = false }) => {
  const [schemaName, setSchemaName] = useState("");
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (initialSchema) {
      setSchemaName(initialSchema.schema_name);
      setFields(convertPropertiesToFields(initialSchema.properties));
    } else {
      setSchemaName("");
      setFields([]);
    }
  }, [initialSchema]);

  const convertPropertiesToFields = (properties) => {
    return Object.entries(properties).map(([key, value]) => ({
      key,
      label: value.label || key,
      type: value.type,
      value: value.value_field,
      required: value.required,
      isArray: false,
      placeholder: value.placeholder || "",
      Enum: value.enum ? value.enum.join(", ") : "",
      hint: value.hint || "",
      alias: value.alias || "",
      properties: value.properties ? convertPropertiesToFields(value.properties) : [],
    }));
  };

  const addField = (parentIndex = null) => {
    const newField = {
      key: `field_${Date.now()}`,
      label: "",
      type: "object",
      value: "",
      required: false,
      isArray: false,
      placeholder: "",
      Enum: "",
      hint: "",
      alias: "",
    };

    if (parentIndex === null) {
      setFields([...fields, newField]);
    } else {
      const updatedFields = [...fields];
      const addNestedField = (fields, index) => {
        if (index.length === 1) {
          if (!fields[index[0]].properties) {
            fields[index[0]].properties = [];
          }
          fields[index[0]].properties.push(newField);
        } else {
          addNestedField(fields[index[0]].properties, index.slice(1));
        }
      };
      addNestedField(updatedFields, parentIndex);
      setFields(updatedFields);
    }
  };

  const deleteNestedField = (indexToDelete) => {
    const updatedFields = [...fields];
    const deleteField = (fields, index) => {
      if (index.length === 1) {
        fields.splice(index[0], 1);
      } else {
        deleteField(fields[index[0]].properties, index.slice(1));
      }
    };
    deleteField(updatedFields, indexToDelete);
    setFields(updatedFields);
  };

  const updateField = (index, updates) => {
    setFields((prevFields) => {
      const newFields = [...prevFields];
      const updateNestedField = (fields, idx) => {
        if (idx.length === 1) {
          fields[idx[0]] = { ...fields[idx[0]], ...updates };
        } else {
          if (!fields[idx[0]].properties) {
            fields[idx[0]].properties = [];
          }
          updateNestedField(fields[idx[0]].properties, idx.slice(1));
        }
      };
      updateNestedField(newFields, index);
      return [...newFields];
    });
  };

  const handleTypeChange = (index, value) => {
    updateField(index, { type: value });
  };

  const handleLabelChange = (index, value) => {
    updateField(index, { label: value });
  };

  const handleRequiredChange = (index) => {
    updateField(index, { required: !fields[index[0]].required });
  };

  const handlePlaceholderChange = (index, value) => {
    updateField(index, { placeholder: value });
  };

  const handleHintChange = (index, value) => {
    updateField(index, { hint: value });
  };

  const handleAliasChange = (index, value) => {
    updateField(index, { alias: value });
  };

  const onValueChange = (index, value) => {
    updateField(index, { value: value });
  };

  const handleEnumChange = (index, value) => {
    updateField(index, { Enum: value });
  };

  const generateSchema = () => {
    const convertToSchema = (fields) => {
      const properties = {};

      fields.forEach((field) => {
        const property = {
          label: field.label,
          type: field.type,
          value_field: field.value || undefined,
          hint: field.hint || undefined,
          alias: field.alias || undefined,
          placeholder: field.placeholder || undefined,
          enum: field.Enum ? field.Enum.split(",").map((val) => val.trim()) : undefined,
          required: field.required,
        };

        if (field.properties && field.properties.length > 0) {
          property.properties = convertToSchema(field.properties);
        }

        properties[field.label] = property;
        // properties[field.key] = property;
      });

      return properties;
    };

    return {
      schema_name: schemaName,
      type: "object",
      properties: convertToSchema(fields),
    };
  };

  const handleSave = () => {
    const schema = generateSchema();
    onSave(schema);
  };

  const renderFields = (fields, parentIndex = []) => {
    return fields.map((field, index) => {
      const currentIndex = [...parentIndex, index];
      const fieldId = `field_${currentIndex.join("_")}`;

      return (
        <div key={fieldId} style={{ marginLeft: parentIndex.length * 20, marginBottom: 16 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item label="Field">
                <Input
                  placeholder="Variable Name here. Eg. Name, SN, etc."
                  required
                  value={field.label}
                  onChange={(e) => handleLabelChange(currentIndex, e.target.value)}
                  readOnly={readOnly}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="Type">
                {readOnly ? (
                  <Input value={field.type} readOnly />
                ) : (
                  <Select
                    value={field.type}
                    onChange={(value) => handleTypeChange(currentIndex, value)}
                    style={{ width: "100%" }}
                  >
                    <Option value="integer">Integer</Option>
                    <Option value="object">Object</Option>
                    <Option value="boolean">Boolean</Option>
                    <Option value="string">String</Option>
                    <Option value="array">Array</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="Value">
                <FormInputs
                  placeholder="Insert the value for the field if required."
                  inputType={field.type}
                  value={field.value}
                  currentIndex={currentIndex}
                  onValueChange={onValueChange}
                  readOnly={readOnly}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="Hint">
                <Input
                  placeholder="Write the hint to be shown for the field."
                  value={field.hint}
                  onChange={(e) => handleHintChange(currentIndex, e.target.value)}
                  readOnly={readOnly}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="Alias">
                <Input
                  placeholder="Alias of the field name here."
                  value={field.alias}
                  onChange={(e) => handleAliasChange(currentIndex, e.target.value)}
                  readOnly={readOnly}
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={12}>
              <Form.Item label="Placeholder">
                <Input
                  placeholder="Placeholder"
                  value={field.placeholder}
                  onChange={(e) => handlePlaceholderChange(currentIndex, e.target.value)}
                  readOnly={readOnly}
                />
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={12}>
              <Form.Item label="Enum">
                <Input
                  placeholder="Values you want as option, seperated by comma."
                  value={field.Enum}
                  onChange={(e) => handleEnumChange(currentIndex, e.target.value)}
                  readOnly={readOnly}
                />
              </Form.Item>
            </Col>
            {!readOnly && (
              <Col xs={24} sm={12}>
                <Space>
                  <Button
                    type={field.required ? "primary" : "default"}
                    onClick={() => handleRequiredChange(currentIndex)}
                  >
                    {field.required ? "Required" : "Not Required"}
                  </Button>
                  {field.type !== "string" && field.type !== "boolean" && (
                    <Button type="dashed" onClick={() => addField(currentIndex)}>
                      Add Nested
                    </Button>
                  )}
                  <Tooltip title="Delete Property" placement="bottom">
                    <Popconfirm
                      placement="left"
                      title={
                        <div>
                          <span>
                            Are you sure to delete this object?
                            <br />
                            All child will be deleted too
                          </span>
                        </div>
                      }
                      onConfirm={() => deleteNestedField(currentIndex)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="primary" danger icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                </Space>
              </Col>
            )}
          </Row>
          {renderFields(field.properties || [], currentIndex)}
        </div>
      );
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Form.Item label="Schema Name">
            <Input
              placeholder="Enter Schema Name"
              value={schemaName}
              onChange={(e) => setSchemaName(e.target.value)}
              readOnly={readOnly}
            />
          </Form.Item>
        </Col>
      </Row>
      {renderFields(fields)}
      {!readOnly && (
        <div style={{ marginTop: 16 }}>
          <Button type="primary" onClick={() => addField()} style={{ marginRight: 10 }}>
            Add Field
          </Button>
          <Button type="primary" onClick={handleSave}>
            {isNewSchema ? "Save Schema" : "Update Schema"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CreateCustomSchema;