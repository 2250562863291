import React, { useState, useEffect } from "react";
import {
  Card,
  Spin,
  Space,
  Select,
  Table,
  Input,
  Typography,
  DatePicker,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import { API_URL } from "../utils/jobApi";
import { debounce } from "lodash";
// import useAssignJobsData from "../hooks/useAssignJobsData";
import { set } from "lodash";
import { Verified } from "lucide-react";

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const SelectJobs = ({
  projectsData,
  selectedProjects,
  handleProjectSelect,
  verificationFilter,
  handleVerificationFilterChange,
  handleJobSearchChange,
  filteredJobs,
  handleJobRowClick,
  rowClassName,
  jobColumns,
}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [filteredJobsState, setFilteredJobsState] = useState(filteredJobs);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  // const { loading: filteredJobsLoading } = useAssignJobsData();
  const [totalJobs, setTotalJobs] = useState(0);
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const fetchJobLists = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("accessToken");

      if (!token) {
        throw new Error("User is not authenticated. Please log in.");
      }

      // Construct query parameters
      const params = new URLSearchParams();
      params.append("pageSize", pageSize);
      params.append("currentPage", currentPage);

      selectedProjectIds.forEach((id) => {
        params.append("projectIds", id); // Sends each ID individually as projectIds=123&projectIds=456
      });

      if (dateRange[0] && dateRange[1]) {
        params.append("start_date", dateRange[0].format("YYYY-MM-DD"));
        params.append("end_date", dateRange[1].format("YYYY-MM-DD"));
      }

      if (verificationFilter !== "all") {
        params.append("verified", verificationFilter === "verified");
      }
      console.log("params", params.toString());
      const response = await axios.get(
        `${API_URL}/projects-with-jobs?${params.toString()}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || !Array.isArray(response.data.data)) {
        throw new Error("Invalid API response structure");
      }
      console.log("API Response:", response.data);

      // Update state with the response data
      setFilteredJobsState(response.data.data);
      console.log("Table after API responser:",response.data.data);
      setTotalJobs(response.data.total);
    } catch (error) {
      console.error("Error fetching projects with jobs:", error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchJobLists();
    console.log("selectedProjectIds", selectedProjectIds);
    console.log("dateRange", dateRange);
  }, [
    pageSize,
    currentPage,
    dateRange,
    selectedProjectIds,
    verificationFilter,
  ]);

  // Filter jobs based on the selected date range
  // const filterJobsByDate = (jobs, range) => {
  //   if (!range || !range[0] || !range[1]) return jobs; // If no date range is selected, return all jobs
  //   const startDate = new Date(range[0]);
  //   const endDate = new Date(range[1]);

  //   return jobs.filter((job) => {
  //     const executedAtDate = new Date(job.created_at);
  //     return executedAtDate >= startDate && executedAtDate <= endDate;
  //   });
  // };


  // useEffect(() => {
  //   const validDateRange = Array.isArray(dateRange) ? dateRange : [null, null];
  //   const jobsFilteredByDate = validDateRange.every((date) => date === null)
  //     ? filteredJobs // No date range selected, display all jobs
  //     : filterJobsByDate(filteredJobs, validDateRange); // Apply date filtering

  //   setFilteredJobsState(jobsFilteredByDate);
  // }, [filteredJobs, dateRange]);

  // Handle DateRangePicker change
  const handleDateRangeChange = (dates) => {
    setDateRange(dates || [null, null]);
    setCurrentPage(1); // Reset to the first page when filtering
  };

  // Handle pagination changes
  // const handleTableChange = (pagination) => {
  //   setCurrentPage(pagination.current);
  //   setPageSize(pagination.pageSize);
  // };

  const handleProjectChange = (selectedValues) => {
    setSelectedProjectIds(selectedValues);
    setCurrentPage(1); // Reset to the first page when the project selection changes
  };

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const handleSearch = debounce(async (value) => {
    setSearchText(value);

    if (!value) {
      setSearchResults([]);
      return;
    }

    setSearching(true);
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        throw new Error("User is not authenticated. Please log in.");
      }

      const response = await axios.get(`${API_URL}/projects`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: { search: value },
      });

      if (response.data && Array.isArray(response.data)) {
        setSearchResults(response.data);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error("Error fetching project search data:", error);
    } finally {
      setSearching(false);
    }
  }, 300);

  const jobColumn = [
    // {
    //   title:Verified,
    //   dataIndex: "verified",
    //   key: "verified",
    //   render: (verified) => (
    //     <span>
    //       {verified ? (
    //         <CheckCircleOutlined style={{ color: "green" }} />
    //       ) : (
    //         <CloseCircleOutlined style={{ color: "red" }} />
    //       )}
    //     </span>
    //   )
    // },
    {
      title: "Job Name",
      dataIndex: "job_name", // Match the key in the API response
      key: "job_name",
    },
    {
      title: "Project Name",
      dataIndex: "project_name", // Match the key for project name in the API response
      key: "project_name",
    },
    {
      title: "Assigned To",
      dataIndex: "assigned_to", // Match the key for 'assigned_to' in the API response
      key: "assigned_to",
    },
  ];
  
  return (
    <Card
      title={
        <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text strong>Select Jobs</Text>
          {/* <Space>
            <Select
              value={verificationFilter}
              onChange={handleVerificationFilterChange}
              style={{ width: 150 }}
              options={[
                { label: "All", value: "all" },
                { label: "Verified", value: "verified" },
                { label: "Unverified", value: "unverified" },
              ]}
            /> */}

            {/* Instant Search Input */}
            {/* <Search
              placeholder="Search by Job or Agent"
              allowClear
              onChange={handleJobSearchChange}
              style={{ width: 200 }}
              prefix={<SearchOutlined />}
            /> */}
          {/* </Space> */}
        </Space>
      }
      bordered={true}
      style={{ height: "100%" }}
    >
      {/* Project Multiselect */}
      <Space
        direction="vertical"
        style={{ width: "100%", marginBottom: "16px" }}
      >
        <Select
          mode="multiple"
          allowClear
          placeholder="Filter by Projects"
          onChange={handleProjectSelect}
          value={selectedProjectIds}
          style={{ width: "100%" }}
          showSearch
          onDropdownVisibleChange={(open) => setDropdownOpen(open)}
          onSearch={dropdownOpen ? handleSearch : undefined}
          loading={searching}
          optionFilterProp="children"
          filterOption={false}
          notFoundContent={searching ? "Searching..." : "No projects found"}
          maxTagCount="responsive"
        >
          {searchResults
            .filter((proj) => proj.name.toLowerCase().includes(searchText.toLowerCase()))
            .map((proj) => (
              <Option key={proj.id} value={proj.id}>
                {proj.name}
              </Option>
            ))}
        </Select>
      </Space>

      {/* Date Range Picker below search box */}
      <RangePicker
        placeholder={["Job Execution Start Date", "Job Execution End Date"]}
        onChange={handleDateRangeChange}
        style={{ width: "100%", marginBottom: "16px" }}
        format="YYYY-MM-DD"
      />

      {/* Jobs Table */}
      <Spin spinning={loading}>
        <Table
          style={{ width: "100%", overflow: "auto" }}
          dataSource={filteredJobsState} 
          columns={jobColumns}
          loading={loading}
          rowClassName={rowClassName}
          rowKey={(record) => record.job_id}
          onRow={(record) => ({
            onClick: () => handleJobRowClick(record.job_id),
          })}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            // onChange: handleTableChange,
            onChange: handlePageChange,
            showSizeChanger: true,
            onShowSizeChange: (current, size) => setPageSize(size),
            // total: filteredJobsState.length,
            total: totalJobs,
            showTotal: (total) => `Total ${total} items`
            // showSizeChanger: true,
          }}
          // rowKey="id"
          // size="middle"
          // scroll={{ y: 300 }}
          // onRow={(record) => ({
          //   onClick: () => handleJobRowClick(record.id),
          // })}
          // onChange={handleTableChange}
          // rowClassName={rowClassName}
        />
      </Spin>
    </Card>
  );
};

export default SelectJobs;
