import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Button, Modal, Tooltip } from "antd";
import {
  UserAddOutlined, // Icon for the floating button
} from "@ant-design/icons";

import Navbar from "./components/Navbar";
import AdminDashboard from "./pages/AdminDashboard";
import AgentDashboard from "./pages/AgentDashboard";
import ProjectManagement from "./pages/ProjectManagement";
import ProjectDetail from "./pages/ProjectDetail";
import JobDetail from "./pages/JobDetail";
import LoginPage from "./pages/LoginPage";
import ChangePasswordComponent from "./components/ChangePasswordForm.js";
import InviteAgentsForm from "./components/InviteAgentsForm"; // Import the InviteAgentsForm
import InvitationPage from "./pages/InvitationPage";
import CreateSchema from "./pages/CreateSchema";
import AssignJobs from "./pages/AssignJobs";
import FailedJobs from "./pages/ActivityLog.js";
import NotFoundPage from "./pages/NotFoundPage.js";
import ProtectedRoute from "./components/ProtectedRoute";
import { getUserRole } from "./utils/auth";
import "./App.css"; // Ensure you have this file for any custom styles
import ManageSubordinate from "./pages/ManageSubordinate";

/**
 * Main Application Component.
 * Handles routing and conditional rendering based on authentication.
 */
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);
  const location = useLocation();

  // State to control the visibility of the Invite Agents modal
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);

  useEffect(() => {
    const userRole = getUserRole();
    setIsAuthenticated(!!userRole);
    setRole(userRole);
  }, [isAuthenticated]);

  // Determine if the Navbar should be displayed
  // Navbar is hidden on '/login' and '/invitation' routes
  const showNavbar =
    !["/", "/invitation"].includes(location.pathname) &&
    !location.pathname.endsWith("/login");

  // Handlers to open and close the Invite Agents modal
  const showInviteModal = () => {
    setIsInviteModalVisible(true);
  };

  const handleInviteModalClose = () => {
    setIsInviteModalVisible(false);
  };

  return (
    <div>
      {showNavbar && (
        <Navbar
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
          setRole={setRole}
        />
      )}
      <Routes>
        {/* Public Route: Login Page with a slug between /api/ and /login */}
        <Route
          path="/:slug/login"
          element={
            <LoginPage
              setIsAuthenticated={setIsAuthenticated}
              setRole={setRole}
            />
          }
        />

        {/* Public Route: Invitation Page */}
        <Route path="/invitation" element={<InvitationPage />} />

        {/* Protected Route: Dashboard */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute allowedRoles={["admin", "supervisor", "agent"]}>
              {role === "admin" && <AdminDashboard />}
              {role === "supervisor" && <AdminDashboard />}
              {role === "agent" && <AgentDashboard />}
            </ProtectedRoute>
          }
        />

        {/* Protected Route: Project Management */}
        <Route
          path="/project-management"
          element={
            <ProtectedRoute allowedRoles={["admin", "supervisor"]}>
              <ProjectManagement />
            </ProtectedRoute>
          }
        />

        {/* Protected Route: Assign Jobs */}
        <Route
          path="/assign-jobs"
          element={
            <ProtectedRoute allowedRoles={["admin", "supervisor"]}>
              <AssignJobs />
            </ProtectedRoute>
          }
        />

        {/* Protected Route: Project Detail */}
        <Route
          path="/project/:id"
          element={
            <ProtectedRoute allowedRoles={["admin", "supervisor"]}>
              <ProjectDetail />
            </ProtectedRoute>
          }
        />

        {/* Protected Route: Job Detail */}
        <Route
          path="/project/:id/job/:jobId"
          element={
            <ProtectedRoute allowedRoles={["admin", "supervisor", "agent"]}>
              <JobDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path="/failed-jobs"
          element={
            <ProtectedRoute allowedRoles={["agent", "admin", "supervisor"]}>
              <FailedJobs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <ProtectedRoute allowedRoles={["admin", "supervisor", "agent"]}>
              <ChangePasswordComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manage-subordinates"
          element={
            <ProtectedRoute allowedRoles={["admin"]}>
              <ManageSubordinate />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/users-manegement"
          element={
            <ProtectedRoute allowedRoles={['root']}>
              <ManageUsers />
             </ProtectedRoute>
          }
        /> */}
        <Route
        path="/schema"
        element={
          <ProtectedRoute allowedRoles={['admin','supervisor','agent']}>
            <CreateSchema />
          </ProtectedRoute>
        }
      />

        {/* Catch-All Route for Undefined Paths */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      {/* Floating Button to Open Invite Agents Modal */}
      {showNavbar && getUserRole() !== "agent" && (
        <Tooltip title="Invite Users">
          <Button
            type="primary"
            shape="circle"
            size="large"
            icon={<UserAddOutlined style={{ fontSize: "30px" }} />}
            onClick={showInviteModal}
            style={{
              position: "fixed",
              right: 50,
              bottom: 50,
              zIndex: 1000,
              width: "60px",
              height: "60px",
            }}
          />
        </Tooltip>
      )}

      {/* Invite Agents Modal */}
      {showNavbar && (
        <Modal
          title="Invite a new User"
          visible={isInviteModalVisible}
          onCancel={handleInviteModalClose}
          footer={null} // Remove default footer to use the form's submit button
          width={800} // Adjust width as needed
          destroyOnClose // Destroy modal content on close to reset the form
        >
          <InviteAgentsForm onClose={handleInviteModalClose} />
        </Modal>
      )}
    </div>
  );
};

export default App;
