import React, { useState, useEffect } from "react";
import { Table, Card, Button, Form, Input, Select,Spin, Space, message, Layout, Row, Col, Pagination, Dropdown } from "antd";
import { DownOutlined, SaveOutlined } from "@ant-design/icons";
import { getAccessToken } from "../utils/auth";
import {
  verifyImage,
  registerStudents,
  saveOutputData,
  syncAgData,
} from "../utils/jobApi";
import downloadMenu from "./DownloadMenu";
import SendToTenantButton from "./SendToTenant";
const { Content } = Layout;
const { Option } = Select;

export default function QATable({ data, tData,currentImagePath,imageIndex,jobName ,handleVerify,verifying,jobId}) {
  const [studentData, setStudentData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    Roll: "",
    Set:""
  });
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 60; // 5 rows * 4 columns
  
  const [isPluginTenant, setIsPluginTenant] = useState("");
  useEffect(() => {
    const tenantSlug = localStorage.getItem("tenant_slug");
    if (tenantSlug) {
      setIsPluginTenant(tenantSlug);
    }
  }, []);


  useEffect(() => {
    console.log("Omr view data", tData);

    const { omr_data: omrData, gemini_data: geminiData } = tData.data;


    console.log("OMR Data:", omrData);
    console.log("Gemini Data:", geminiData);

    // Set student data from gemini_data

    setStudentData({
      first_name: geminiData[0],
      middle_name: geminiData[1],
      last_name: geminiData[2],
      Roll: omrData.Roll || "",
      Set:omrData.Set||""
    });

    // Extract question-answer pairs from omr_data
    const qaData = Object.entries(omrData)
      .filter(([key]) => key.startsWith("q"))
      .map(([key, value]) => ({
        key,
        questionNumber: key,
        answer: value || null
      }));

    setTableData(qaData); // Set qaData as table data
  }, [tData]); // Watch for changes in tData

  const handleAnswerChange = (key, newValue) => {
    setTableData((prev) =>
      prev.map((row) =>
        row.key === key ? { ...row, answer: newValue } : row
      )
    );
  };

  const handleStudentDataChange = (field, value) => {
    setStudentData((prev) => ({ ...prev, [field]: value }));
  };


  const generate_saveData=() => {
    const savedData = {
      job:jobName,
      image:currentImagePath,
      index:imageIndex,
     output: {
            omr_data: {
              Roll: studentData.Roll,
              Set:studentData.Set,
              ...tableData.reduce((acc, { questionNumber, answer }) => ({
                ...acc,
                [questionNumber]: answer
              }), {})
            },
            gemini_data: [
              studentData.first_name,
              studentData.middle_name,
              studentData.last_name

            ]
    }
  };
    return savedData
    
  }

  const handleSave = async () => {
    setLoading(true);
    try {
      const savedData=generate_saveData();


      console.log("Saved data:", savedData);
      saveOutputData(getAccessToken(),savedData);
      message.success("Data saved successfully");
      
      
      verifyImage(getAccessToken(), savedData);

    } catch (error) {
      message.error("Failed to save data");
    } finally {
      setLoading(false);
    }
  };

  const renderQuestionGroup = (questions) => {
    return (
      <Row gutter={[18, 18]}>
        {questions.map((question) => (
          <Col span={4} key={question.key}>
            <Form.Item label={question.questionNumber}>
              <Select
                value={question.answer}
                style={{ width: "70%" }}
                onChange={(newValue) => handleAnswerChange(question.key, newValue)}
              >
                <Option value="null">-</Option>
                <Option value="A">A</Option>
                <Option value="B">B</Option>
                <Option value="C">C</Option>
                <Option value="D">D</Option>
              </Select>
            </Form.Item>
          </Col>
        ))}
      </Row>
    );
  };
  const handleSend = async () => {
    const savedData=generate_saveData();

    console.log("Sending data:", savedData);
    message.success("Data sent successfully");
  }

  const paginatedData = tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  const groupedData = [];
  for (let i = 0; i < paginatedData.length; i += 6) { // Change 4 to 6
    groupedData.push(paginatedData.slice(i, i + 6)); // Grouping questions
  }
  

  return (
    <Layout>
      <Content style={{ padding: 24 }}>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Card>
            <Form layout="horizontal">
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label="First Name">
                    <Input
                      value={studentData.first_name}
                      onChange={(e) =>
                        handleStudentDataChange("first_name", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Middle Name">
                    <Input
                      value={studentData.middle_name}
                      onChange={(e) =>
                        handleStudentDataChange("middle_name", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Last Name">
                    <Input
                      value={studentData.last_name}
                      onChange={(e) =>
                        handleStudentDataChange("last_name", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Roll Number">
                    <Input
                      value={studentData.Roll}
                      onChange={(e) =>
                        handleStudentDataChange("Roll", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>

          <Card>
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        

              {groupedData.map((group, index) => (
                <React.Fragment key={index}>
                  {renderQuestionGroup(group)}
                </React.Fragment>
              ))}

              <Pagination
                current={currentPage}
                total={tableData.length}
                pageSize={pageSize}
                onChange={(page) => setCurrentPage(page)}
                showSizeChanger={false}
                showQuickJumper
              />
            </Space>
          </Card>
            <Space>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleSave}
              loading={loading}
            >
              Save 
            </Button>

            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="button"
              disabled={tableData.length === 0}
              onClick={handleSend}
            >
              {verifying ? (
              <Spin size="small" />
              ) : (
              `Send to ${localStorage.getItem("tenant_slug")} `
              )}
            </Button>
            {/* <SendToTenantButton
              jobId={jobId}
              imagePath={currentImagePath}
              studentData={studentData}
              tableData={tableData}
              isSending={loading}
            /> */}

            <Dropdown overlay={downloadMenu(1, jobId)} trigger={["click"]}>
              <Button type="primary" icon={<DownOutlined />} htmlType="button">
              {/* {saving ? <Spin size="small" /> : "Download"} */}
              Download
            </Button>
          </Dropdown>
            </Space>

        </Space>
      </Content>
    </Layout>
  );
}
