// src/utils/assignJobsUtils.js
import axios from "axios"; // Ensure you import axios like this
import { API_URL } from "./jobApi";
import { getAccessToken } from "./auth";
// Utility functions for AssignJobs

/**
 * Get all jobs from the projects data.
 * @param {Array} projectsData - The list of projects.
 * @returns {Array} - The list of all jobs with project information.
 */
export const getAllJobs = (projectsData) => {
    let allJobs = [];
    projectsData.forEach((project) => {
      allJobs = [
        ...allJobs,
        ...project.jobs.map((job) => ({
          ...job,
          projectId: String(project.id), // Ensure projectId is a string
          projectName: project.name,
          id: String(job.id), // Ensure job id is a string
        })),
      ];
    });
    return allJobs;
  };
  
  export const getAgentById = (agentsData, agentId) => {
    const agent = agentsData.find((agent) => String(agent.id) === String(agentId));
    // if (!agent) {
    //   console.warn(`Agent not found for agentId: ${agentId}`);
    // }
    return agent;
  };
  
  export const getJobById = async (allJobs, jobId) => {
  //   const job = allJobs.find((job) => String(job.id) === String(jobId));
  //   if (!job) {
  //     console.warn(`Job not found in getJobById for jobId: ${jobId}`);
  //   }
  //   return job;
  // };
    const token = getAccessToken();
    if (!token) {
      throw new Error("User is not authenticated. Please log in.");
    }

    try {
      console.log(
        `Job not found locally, fetching job with ID: ${jobId} from API`
      );

      const response = await axios.get(`${API_URL}/job/${jobId}`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`, // Authorization header with Bearer token
        },
      });

      if (response.data) {
        console.log(`Job fetched from API:`, response.data);
        return response.data;
      } else {
        console.warn(`Job not found in API for jobId: ${jobId}`);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching job with jobId: ${jobId} from API`, error);
      return null;
    }
  };