// import { Input, Switch, Form } from "antd";
// import React from "react";

// const FormInputs = ({ inputType, value, onValueChange, currentIndex }) => {
//   const handleInputChange = (e) => {
//     onValueChange(currentIndex, e.target.value);
//   };

//   return (
//     <Form.Item>
//       {inputType === "string" && (
//         <Input
//           placeholder="Type the string value"
//           value={value}
//           onChange={handleInputChange}
//         />
//       )}

//       {inputType === "boolean" && (
//         <Switch
//           checked={value}
//           onChange={(checked) => onValueChange(currentIndex, checked)}
//         />
//       )}

//       {inputType === "integer" && (
//         <Input
//           type="number"
//           placeholder="Type the integer value"
//           value={value}
//           onChange={handleInputChange}
//         />
//       )}
//     </Form.Item>
//   );
// };

// export default FormInputs;

import React from "react"
import { Input, Switch, InputNumber } from "antd"

const FormInputs = ({ inputType, value, currentIndex, onValueChange, readOnly }) => {
  switch (inputType) {
    case "string":
      return <Input value={value} onChange={(e) => onValueChange(currentIndex, e.target.value)} readOnly={readOnly} />
    case "boolean":
      return <Switch checked={value} onChange={(checked) => onValueChange(currentIndex, checked)} disabled={readOnly} />
    case "integer":
      return (
        <InputNumber value={value} onChange={(newValue) => onValueChange(currentIndex, newValue)} readOnly={readOnly} />
      )
    default:
      return null
  }
}

export default FormInputs

