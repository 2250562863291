import React, { useState, useEffect } from "react";
import { Button, Spin, message } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { checkHookStatus, sendData } from "../utils/checkHook";

const SendToTenantButton = ({ jobId, imagePath, studentData, tableData, isSending, onSent }) => {
  const [hookExists, setHookExists] = useState(false);
  const [checkingHook, setCheckingHook] = useState(true);

  // Centralized function to generate the correct payload format
  const generateVerifyBody = () => {
    return {
      "jobId":jobId, // Passed as a prop
      "ImagePath" : imagePath, // Passed as a prop
      "outputData": [
        {
        "test":{
          "output1":"hi"
        }
      },
    ],
    };
  };

  useEffect(() => {
    const fetchHookStatus = async () => {
      setCheckingHook(true);
      try {
        const exists = await checkHookStatus();
        setHookExists(exists);
      } catch (error) {
        message.error("Failed to check hook status");
      } finally {
        setCheckingHook(false);
      }
    };

    fetchHookStatus();
  }, [jobId]); // Add jobId as a dependency in case it changes

  const handleSend = async () => {
    const verifyBody = generateVerifyBody();

    try {
      message.loading("Sending data...");
      await sendData(verifyBody); // Call the API with the generated payload
      message.success("Data sent successfully");
      if (onSent) onSent(); // Optional callback to notify parent of successful send
    } catch (error) {
      message.error("Failed to send data");
    }
  };

  if (checkingHook) {
    return <Spin size="small" />;
  }

  if (!hookExists) {
    return null; // Don't render anything if the hook doesn't exist
  }

  return (
    <Button
      type="primary"
      icon={<SaveOutlined />}
      htmlType="button"
      onClick={handleSend}
      disabled={isSending}
    >
      {isSending ? <Spin size="small" /> : `Send to ${localStorage.getItem("tenant_slug")}`}
    </Button>
  );
};

export default SendToTenantButton;
