// src/utils/dashboardApi.js
import axios from 'axios';
import { getAccessToken } from './auth';
import { API_URL } from '../utils/jobApi';


// Fetch the total number of jobs
export const fetchJobsCount = async () => {
  const response = await axios.get(`${API_URL}/jobs/count`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  console.log("Jobs's Count: ", response.data)
  return response.data;
};

// Fetch the number of projects with jobs
export const fetchProjectsCount = async () => {
  const response = await axios.get(`${API_URL}/projects/jobs/count`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  console.log("Project's Count: ", response.data)
  return response.data;
};

// Fetch jobs assigned to agents
export const fetchAgentJobs = async (agentUsername) => {
  const response = await axios.get(`${API_URL}/agents/${agentUsername}/jobs`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  console.log("Agent jobs: ", response.data)
  return response.data;
};


// Fetch counts of users by role
export const fetchUserCountsByRole = async (startDate, endDate) => {
  const response = await axios.get(`${API_URL}/users/roles/count`, {
    params: {
      start_date: startDate.format("YYYY-MM-DD"),  
      end_date: endDate.format("YYYY-MM-DD"),      
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  return response.data;
};

// Fetch counts of jobs by status
export const fetchJobCountsByStatus = async (startDate, endDate) => {
  const response = await axios.get(`${API_URL}/jobs/status/count`, {
    params: {
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  return response.data;
};

// Fetch counts of verified vs non-verified jobs
export const fetchVerifiedJobCounts = async (startDate, endDate) => {
  const response = await axios.get(`${API_URL}/jobs/verified/counts`, {
    params:{
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  return response.data;
};

// Fetch counts of unassigned jobs
export const fetchUnassignedJobsCount = async (startDate,endDate) => {
  const response = await axios.get(`${API_URL}/jobs/unassigned/count`, {
    params: {
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  return response.data;
};

// Fetch jobs assigned to an agent
export const fetchAgentAssignedJobs = async (agentUsername) => {
  const response = await axios.get(`${API_URL}/agents/${agentUsername}/jobs`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  return response.data;
};

// Fetch agents performance data
export const fetchAgentsPerformanceData = async (startDate, endDate) => {
  const response = await axios.get(`${API_URL}/agents/performance`, {
    params: {
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  return response.data; // Adjust based on your API response
};

// Fetch supervisors/admins assignments data
export const fetchSupervisorsAssignmentsData = async (startDate, endDate) => {
  const response = await axios.get(`${API_URL}/supervisors/assignments`, {
    params: {
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  return response.data; // Adjust based on your API response
};