// src/components/PendingAssignmentsDrawer.js

import React from 'react';
import { Drawer, List, Avatar, Tag, Button, Empty } from 'antd';
import { UserOutlined, CloseOutlined } from '@ant-design/icons';
import { get } from 'lodash';

const PendingAssignmentsDrawer = ({
  isDrawerVisible,
  setIsDrawerVisible,
  pendingAssignments,
  getJobById,
  getAgentById,
  handleRemovePendingAssignment,
  handleConfirmAssignments,
}) => {
  return (
    <Drawer
      title="Pending Assignments"
      placement="right"
      width={400}
      onClose={() => setIsDrawerVisible(false)}
      visible={isDrawerVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      {pendingAssignments.length > 0 ? (
        <div>
          <List
            dataSource={pendingAssignments}
            renderItem={(assignment) => {
              const { jobId, agentId } = assignment;

              const job = getJobById(jobId);
              const agent = getAgentById(agentId);


              // Defensive checks
              if (!job || !agent) {
                console.warn(
                  `Job or Agent not found for jobId: ${jobId}, agentId: ${agentId}`
                );
                return null;
              }

              return (
                <List.Item
                  key={jobId}
                  actions={[
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={() => handleRemovePendingAssignment(jobId)}
                    />,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<Avatar icon={<UserOutlined />} />}
                    title={job.name}
                    description={
                      <>
                        <Tag color="geekblue">{job.projectName}</Tag>
                        <Tag color="green">{agent.name}</Tag>
                      </>
                    }
                  />
                </List.Item>
              );
            }}
            style={{ maxHeight: '300px', overflowY: 'auto' }}
          />
          <Button
            type="primary"
            onClick={handleConfirmAssignments}
            block
            style={{ marginTop: '16px' }}
          >
            Confirm Assignments
          </Button>
        </div>
      ) : (
        <Empty description="No pending assignments" />
      )}
    </Drawer>
  );
};

export default PendingAssignmentsDrawer;
