import { useState, useEffect } from 'react';
import axios from "axios";
import { API_URL } from "../utils/jobApi";
import { getAccessToken } from "../utils/auth";

export const saveCustomSchema = async (schema) => {
    const token = getAccessToken();
    if (!token) {
      throw new Error("User is not authenticated. Please log in.");
    }
  
    try {
      console.log("--",schema)
      const response = await axios.post(`${API_URL}/create_custom_column`, schema, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response)
      return response.status
    } catch (error) {
      console.error("Error checking hook status:", error);
      throw error;
    }
  };

export const getCustomSchema = async () => {
    const token = getAccessToken();
    if (!token) {
      throw new Error("User is not authenticated. Please log in.");
    }
  
    try {
      const response = await axios.get(`${API_URL}/get_custom_column`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error checking hook status:", error);
      throw error;
    }
  };
  
export const deleteCustomSchema = async (id) => {
  const token = getAccessToken();
  if (!token) {
    throw new Error("User is not authenticated. Please log in.");
  }

  try {
    const response = await axios.delete(`${API_URL}/delete_custom_column/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("response", response.status)
    return response.data;
  } catch (error) {
    console.error("Error checking hook status:", error);
    throw error;
  }
};
export const useGetAllCustomSchema = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    const token = getAccessToken();
    if (!token) {
      setError("User is not authenticated. Please log in.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${API_URL}/get_all_custom_columns`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
      setError(null); // Clear errors on success
    } catch (error) {
      console.error("Error fetching custom schemas:", error);
      setError(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  // Run fetchData when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error, refetch: fetchData };
};

export const updateCustomSchema = async (id, schema) => {
  const token = getAccessToken();
  if (!token) {
    throw new Error("User is not authenticated. Please log in.");
  }

  try {
    console.log("update schema",schema)
    const response = await axios.put(`${API_URL}/update_custom_column/${id}`, schema, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error checking hook status:", error);
    throw error;
  }
};