import axios from "axios";
import { API_URL } from "../utils/jobApi";
import { getAccessToken } from "./auth";

export const checkHookStatus = async () => {
  const token = getAccessToken();
  if (!token) {
    throw new Error("User is not authenticated. Please log in.");
  }

  try {
    const response = await axios.get(`${API_URL}/check_hook`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("response", response.data)
    return response.data;
  } catch (error) {
    console.error("Error checking hook status:", error);
    throw error;
  }
};

export const sendData = async (verifyBody) => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error("User is not authenticated. Please log in.");
    }
    console.log(token);
    console.log(verifyBody);
    const response = await axios.post(
      `${API_URL}/send_to_hook`,
      verifyBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};