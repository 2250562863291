// src/components/SelectedJobs.js

import React, { useEffect, useState } from "react";
import { Card, List, Empty, Button, Tag, Space, Typography } from "antd";
import {
  CheckCircleOutlined,
  CloseOutlined,
  ProjectOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const SelectedJobs = ({
  selectedJobs,
  getJobById,
  handleRemoveSelectedJob,
}) => {
  // State to hold the job data once fetched
  const [jobData, setJobData] = useState({});

  useEffect(() => {
    const fetchJob = async (jobId) => {
      const job = await getJobById(jobId);
      setJobData((prevData) => ({ ...prevData, [jobId]: job }));
    };

    // Fetch job data for each selected job
    selectedJobs.forEach((jobId) => {
      console.log("jobId", jobId);
      if (!jobData[jobId]) {
        fetchJob(jobId);
      }
    });
  }, [selectedJobs, jobData, getJobById]);

  return (
    <Card title="Selected Files" style={{ height: "100%" }}>
      {selectedJobs.length > 0 ? (
        <List
          dataSource={selectedJobs}
          renderItem={(jobId) => {
            const job = jobData[jobId]; // Get the job from the state

            // Add a check to handle undefined job
            if (!job) {
              return null;
            }

            return (
              <List.Item
                key={jobId}
                actions={[
                  <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={() => handleRemoveSelectedJob(jobId)}
                  />,
                ]}
                style={{
                  background: "#fff",
                  borderRadius: "4px",
                  marginBottom: "8px",
                }}
              >
                <List.Item.Meta
                  avatar={
                    <CheckCircleOutlined
                      style={{ color: "#52c41a", fontSize: "20px" }}
                    />
                  }
                  title={
                    <div>
                      <Text strong>{job.name} &nbsp; &nbsp;</Text>
                      <Space>
                        <Tag color="geekblue">
                          <ProjectOutlined style={{ marginRight: "4px" }} />
                          {job.project_name}
                        </Tag>
                      </Space>
                    </div>
                  }
                />
              </List.Item>
            );
          }}
          style={{
            maxHeight: "440px",
            overflowY: "auto",
            padding: "8px",
          }}
        />
      ) : (
        <Empty description="No jobs selected" />
      )}
    </Card>
  );
};

export default SelectedJobs;