// src/components/JobList.js

import React, { useState, useEffect } from "react";
import { Table, Input, Button, Tooltip, Popconfirm, message } from "antd";
import {
  ThunderboltOutlined,
  DeleteOutlined,
  EditOutlined,
  ProjectOutlined,
  RightCircleOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useProjectData from "../hooks/useProjectData";
import EditJobModal from "./EditJobModal";
import { API_URL } from "../utils/jobApi";
import axios from "axios";
import { getAccessToken } from "../utils/auth";

const JobList = ({
  loading,
  onSelectJob,
  selectedJobId,
  handleActionClick,
  handleDeleteClick,
  projectId,
  populateJobDetails,
}) => {
  const [searchText, setSearchText] = useState("");
  const [sortedInfo, setSortedInfo] = useState({});
  const [filteredInfo, setFilteredInfo] = useState({
    status: [],
  });
  const [isEditJobModalVisible, setIsEditJobModalVisible] = useState(false);
  const [editJobId, setEditJobId] = useState(null);
  const [jobData, setJobData] = useState({
    jobs: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [loading2, setLoading2] = useState(false);
  const handleActionClickWithRefresh = async (jobId) => {
    await handleActionClick(jobId);
    handleRefreshClick(jobData.page); // Refresh the job list after execution
  };
  
  const navigate = useNavigate();
  const { handleEditJob } = useProjectData(projectId);

  const handleRefreshClick = (pages) => {
    // console.log("refresh done", pages);
    setLoading2(true);
    fetchJobs(pages); // Manual refresh of the table
  };
  // const handleRefreshClick = (pages) => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     fetchJobs(pages); // Manual refresh of the table
  //   }, 2000); // 2-second timeout before calling fetchJobs
  // };

  // Fetch jobs based on filters, pagination, and search
  const fetchJobs = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${getAccessToken()}`,
      };

      const response = await axios.get(`${API_URL}/jobs/${projectId}`, {
        params: {
          job_status: filteredInfo.status ? filteredInfo.status[0] : undefined,
          verified: filteredInfo.verified
            ? filteredInfo.verified[0] === "true"
            : undefined,
          page: jobData.page,
          search_query: searchText,
          page_size: jobData.pageSize,
        },
        headers: headers,
      });

      const { total, page, page_size, jobs } = response.data;

      setJobData({
        jobs: jobs || [],
        total: total || 0,
        page: page,
        pageSize: page_size,
      });
      setLoading2(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      message.error("Failed to fetch jobs. Please try again.");
      setJobData((prev) => ({
        ...prev,
        jobs: [],
      }));
    }
  };
  
  useEffect(() => {
    fetchJobs();
  }, [projectId, filteredInfo, searchText, jobData.page, jobData.pageSize]);

  // Handle table changes (pagination, filtering, sorting)
  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setJobData((prev) => ({
      ...prev,
      page: pagination.current,
      pageSize: pagination.pageSize,
    }));
  };

  // Close the edit job modal
  const handleEditJobClose = () => {
    setIsEditJobModalVisible(false);
    setEditJobId(null);
  };

  // Render job status with icons and tooltips
  const renderStatus = (status) => {
    const statusInfo = {
      pending: {
        text: "Pending",
        color: "warning",
        icon: <ExclamationCircleOutlined />,
        tooltip: "Job is pending and awaiting execution.",
      },
      "in-progress": {
        text: "In Progress",
        color: "processing",
        icon: <SyncOutlined spin style={{ color: "blue" }} />,
        tooltip: "Job is currently being processed.",
      },
      completed: {
        text: "Completed",
        color: "success",
        icon: <CheckCircleOutlined style={{ color: "green" }} />,
        tooltip: "Job has been completed successfully.",
      },
      failed: {
        text: "Failed",
        color: "error",
        icon: <CloseCircleOutlined style={{ color: "red" }} />,
        tooltip: "Job execution failed. Please check the logs.",
      },
    };

    const currentStatus = statusInfo[status] || {
      text: status,
      color: "default",
      icon: null,
      tooltip: "",
    };

    return (
      <Tooltip title={currentStatus.tooltip}>
        {currentStatus.icon} {currentStatus.text}
      </Tooltip>
    );
  };

  // Render verified status
  const renderVerified = (verified) => {
    return verified ? (
      <Tooltip title="Verified">
        <CheckCircleOutlined style={{ color: "green" }} /> Verified
      </Tooltip>
    ) : (
      <Tooltip title="Unverified">
        <CloseCircleOutlined style={{ color: "red" }} /> Unverified
      </Tooltip>
    );
  };

  // Check if a job is executable
  const isExecutable = (status) => {
    return status === "pending" || status === "failed";
  };

  // Table columns
  const columns = [
    {
      title: "S.N",
      dataIndex: "sn",
      key: "sn",
      render: (sn) => sn || "N/A",
      align: "center",
    },
    {
      title: (
        <span
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ProjectOutlined style={{ marginRight: 8 }} /> Job Name
        </span>
      ),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
    },
    // {
    //   title: "Uploaded By",
    //   dataIndex: "created_by",
    //   key: "uploaded_by",
    //   render: (uploaded_by) => uploaded_by || "N/A",
    //   align: "center",
    // },
    // {
    //   title: "Office Name",
    //   dataIndex: "office_name",
    //   key: "office_name",
    //   align: "center",
    //   render: (office_name) => {
    //     if (!office_name) return "N/A";

    //     const words = office_name.split(" ");

    //     const shortenedName =
    //       words.length <= 3
    //         ? office_name // If there are 3 or fewer words, show the full name
    //         : `${words[0]} ... ${words[words.length - 1]}`;

    //     return <Tooltip title={office_name}>{shortenedName}</Tooltip>;
    //   },
    // },

    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        if (!created_at) return "N/A";

        // Format the date to 'yyyy-mm-dd'
        const date = new Date(created_at);
        return date.toLocaleDateString("en-GB"); // This returns 'dd/mm/yyyy', you can adjust to 'yyyy-mm-dd' if needed.
      },
      align: "center",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderStatus,
      filters: [
        { text: "Pending", value: "pending" },
        { text: "In Progress", value: "in-progress" },
        { text: "Completed", value: "completed" },
        { text: "Failed", value: "failed" },
      ],
      filteredValue: filteredInfo.status || null,
    },
    {
      title: "Verified",
      dataIndex: "verified",
      key: "verified",
      render: renderVerified,
      filters: [
        { text: "Verified", value: "true" },
        { text: "Unverified", value: "false" },
      ],
      filteredValue: filteredInfo.verified || null,
    },
    {
      title: "Actions",
      dataIndex: "_id",
      key: "actions",
      align: "center",
      render: (jobId, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {record.status === "completed" ? (
            <>
              <Tooltip title="Go to Job Details">
                <Button
                  type="primary"
                  icon={<RightCircleOutlined />}
                  onClick={() => navigate(`/project/${projectId}/job/${jobId}`)}
                  style={{ marginRight: "8px" }}
                >
                  Go To
                </Button>
              </Tooltip>
              <Tooltip title="Re-execute this job">
                <Button
                  type="primary"
                  icon={<ThunderboltOutlined />}
                  onClick={() => handleActionClickWithRefresh(jobId)}
                >
                  Re-Execute
                </Button>
              </Tooltip>
            </>
          ) : (
            <Tooltip
              title={
                isExecutable(record.status)
                  ? "Execute this job"
                  : "Job cannot be executed unless it is pending or failed"
              }
            >
              <Button
                type="primary"
                icon={<ThunderboltOutlined />}
                onClick={() => handleActionClickWithRefresh(jobId)}
                disabled={!isExecutable(record.status)}
                style={{ marginRight: "8px" }}
              >
                Execute
              </Button>
            </Tooltip>
          )}
          <div style={{ marginLeft: "8px" }}>
            <Popconfirm
              title="Are you sure you want to edit this job?"
              onConfirm={() => setIsEditJobModalVisible(true)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Edit Job">
                <Button
                  type="danger"
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    setEditJobId(jobId);
                    e.stopPropagation();
                  }}
                />
              </Tooltip>
            </Popconfirm>
            <Popconfirm
              title="Are you sure you want to delete this job?"
              onConfirm={() => handleDeleteClick(jobId)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete Job">
                <Button
                  type="danger"
                  icon={<DeleteOutlined />}
                  onClick={(e) => e.stopPropagation()}
                />
              </Tooltip>
            </Popconfirm> 
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={{ display: "flex" }}>
        <Input.Search
          placeholder="Search by job name"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ marginBottom: 8, marginRight: 10 }}
        />
        <Button
          color="rgb(23, 73, 190)"
          variant="solid"
          icon={<ReloadOutlined />}
          onClick={() => handleRefreshClick(jobData.page)} // Explicitly pass jobData.page
          style={{ marginRight: "8px" }}
        >
          Refresh
        </Button>
      </div>
      <Table
        style={{ overflow: "auto" }}
        columns={columns}
        dataSource={jobData.jobs}
        loading={loading}
        rowKey={(record) => record._id}
        pagination={{
          total: jobData.total,
          current: jobData.page,
          pageSize: jobData.pageSize,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        onChange={handleTableChange}
        onRow={(record) => ({
          onClick: () => onSelectJob(record._id),
          style: {
            cursor: "pointer",
            backgroundColor:
              record._id === selectedJobId ? "#e6f7ff" : "transparent",
          },
        })}
      />

      <EditJobModal
        isVisible={isEditJobModalVisible}
        setIsEditJobModalVisible={setIsEditJobModalVisible}
        onCancel={handleEditJobClose}
        onFinish={handleEditJob}
        editJobId={editJobId}
        loading={false}
        populateJobDetails={populateJobDetails}
      />
    </>
  );
};

export default JobList;