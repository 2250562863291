import React, { useEffect, useState } from 'react';
import { Card, List, Input, Tag, Avatar, Button, Empty, Space, Typography, Spin } from 'antd';
import { UserOutlined, CloseOutlined, ProjectOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import { API_URL } from '../utils/jobApi'; // Assuming API_URL is defined somewhere
import { getAccessToken } from '../utils/auth'; // Assuming this is how you get the auth token

const { Text } = Typography;

const AssignmentSummary = ({
  handleRemoveAssignedJobs,
  searchSummaryText,
  setSearchSummaryText,
  pendingAssignments,
  setIsDrawerVisible,
}) => {
  const [assignedJobs, setAssignedJobs] = useState([]);
  const [filteredAssignedJobs, setFilteredAssignedJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [agents, setAgents] = useState([]);
  
  useEffect(() => {
    fetchAssignedJobs();
  }, [searchSummaryText]);

  const fetchAssignedJobs = async () => {
    setLoading(true);
    const token = getAccessToken(); // Assuming this function provides the auth token
    try {
      const response = await axios.get(`${API_URL}/assigned_jobs`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("AssignmentSummary==>", response.data);
      const { jobs, agent_job_count } = response.data;
      const agentNames = Object.keys(agent_job_count);
      const job_count= Object.values(agent_job_count);
      console.log("AgentNamesss=>", agentNames);
      console.log("job_count=>", job_count);
      // Map jobs to each agent
      const agentJobDetails = agentNames.map(agentName => {
        console.log("agentName==>", agentName);
        console.log("jobs==>", jobs);
        const agentJobs = jobs.filter(job => job.assigned_agent_name === agentName);
        console.log("agentJobs==>", agentJobs);
        return {
          agentName,
          jobs: agentJobs,
          jobCount: agent_job_count[agentName],
        };
      });
      console.log("agentJobDetails==>", agentJobDetails);
      setAssignedJobs(jobs);
      setFilteredAssignedJobs(agentJobDetails); // Store agents with jobs
      setLoading(false);
    } catch (error) {
      console.error('Error fetching assigned jobs:', error);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   // Filter jobs based on the search text
  //   const filtered = filteredAssignedJobs.filter((agent) =>
  //     agent.agentName.toLowerCase().includes(searchSummaryText.toLowerCase())
  //   );
  //   console.log("fileted data==>", filtered);
  //   setFilteredAssignedJobs(filtered);
  // }, [searchSummaryText, filteredAssignedJobs]);
  
  return (
    <Card title="Assignment Summary" style={{ marginTop: '24px' }}>
      <div style={{ marginBottom: '16px' }}>
        <Input
          placeholder="Search by agent name"
          value={searchSummaryText}
          onChange={(e) => setSearchSummaryText(e.target.value)}
          style={{ width: '300px' }}
          allowClear
          prefix={<SearchOutlined />}
        />
      </div>

      {loading ? (
        <Spin size="large" />
      ) : filteredAssignedJobs.length > 0 || pendingAssignments.length > 0 ? (
        <div>
          <Button
            type="primary"
            onClick={() => setIsDrawerVisible(true)}
            style={{ marginBottom: '16px' }}
          >
            View Pending Assignments ({pendingAssignments.length})
          </Button>

          <List
            grid={{ gutter: 16, column: 3 }}
            dataSource={filteredAssignedJobs}
            renderItem={(agent) => {
              const agentJobs = agent.jobs || [];
              return (
                <List.Item key={agent.agentName}>
                  <Card
                    title={
                      <Space>
                        <Avatar icon={<UserOutlined />} />
                        <Text strong>{agent.agentName}</Text>
                        <Tag color="blue">{agent.jobCount} Jobs</Tag>
                      </Space>
                    }
                    extra={
                      <Button
                        type="text"
                        icon={<CloseOutlined />}
                        onClick={() => handleRemoveAssignedJobs(agent.agentName)}
                      />
                    }
                    style={{ width: '100%' }}
                  >
                    <List
                      dataSource={agentJobs}
                      renderItem={(job) => (
                        <List.Item key={job.name}>
                          <List.Item.Meta
                            avatar={<ProjectOutlined style={{ color: '#1890ff' }} />}
                            title={
                              <div>
                                <Text>{job.name}</Text>
                                <Tag color="geekblue">{job.project_name}</Tag>
                              </div>
                            }
                          />
                        </List.Item>
                      )}
                      style={{ maxHeight: '200px', overflowY: 'auto' }}
                    />
                  </Card>
                </List.Item>
              );
            }}
          />
        </div>
      ) : (
        <Empty description="No jobs assigned yet" />
      )}
    </Card>
  );
};

export default AssignmentSummary;
