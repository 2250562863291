import React from "react";
import { Menu, message } from "antd";
import { API_URL } from "../utils/jobApi";
import { getAccessToken } from "../utils/auth";

const handleDownload = async (index, type, jobId) => {
  try {
    console.log(jobId, index, type);
    
    const response = await fetch(
      `${API_URL}/jobs/${jobId}/download?format=${type}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );

    if (response.ok) {
      const blob = await response.blob();

      // Dynamically set file name
      const fileName = type === "json"
        ? `job_${jobId}_output_${index}.json`
        : `job_${jobId}_output_${index}.xlsx`;

      // Trigger file download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);

      message.success("File downloaded successfully!");
    } else {
      console.error("File download failed:", response.statusText);
      message.error("Failed to download file.");
    }
  } catch (error) {
    console.error("Error downloading file:", error);
    message.error("An error occurred while downloading.");
  }
};

const downloadMenu = (index, jobId) => (
  <Menu>
    <Menu.Item key="json" onClick={() => handleDownload(index, "json", jobId)}>
      JSON
    </Menu.Item>
    <Menu.Item key="xlsx" onClick={() => handleDownload(index, "xlsx", jobId)}>
      Excel
    </Menu.Item>
  </Menu>
);

export default downloadMenu;
