import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, Upload, Progress, message, Image, Select, Descriptions } from 'antd';
import { 
  ProjectOutlined, 
  ProfileOutlined, 
  FileTextOutlined, 
  PlusOutlined, 
  UploadOutlined, 
  TagsOutlined, 
  TableOutlined, 
  FileDoneOutlined, 
  BookOutlined ,
  CustomerServiceOutlined
} from '@ant-design/icons';
import { createJobWithImages } from '../utils/jobApi';
import {getCustomSchema} from "../hooks/customSchema";
import { Group } from 'lucide-react';


const { Option } = Select;

const jobOptions = [
  { value: 'table', label: 'Table', icon: <TableOutlined /> },
  { value: 'receipt', label: 'Receipt', icon: <FileDoneOutlined /> },
  { value: 'book_notes', label: 'Book/Notes', icon: <BookOutlined /> },
  {
    value: 'omr',
    label: 'OMR',
    icon: <ProfileOutlined />,
    subOptions: [
      { value: 'omr/50', label: 'OMR/50', icon: <ProfileOutlined /> },
      { value: 'omr/100', label: 'OMR/100', icon: <ProfileOutlined /> },
      { value: 'omr/200', label: 'OMR/200', icon: <ProfileOutlined /> },
    ],
  },
  {value: "Custom Schema", label: "Custom Schema"}
];


const CreateJobModal = ({ projectId,projectType, isVisible, onCancel, onFinish, loading }) => {
  const [fileList, setFileList] = useState([]);
  const [overallProgress, setOverallProgress] = useState(0);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [columnsOptions, setColumnsOptions] = useState([]);
  const [loadingColumns, setLoadingColumns] = useState(true);
  const [customSchemas, setCustomSchemas] = useState([]); // State for custom schemas
  const [type, setType] = useState("");
  const [omrSubType, setOmrSubType] = useState(""); // State for OMR sub-type


  const currentDateTime = new Date().toLocaleString();
  // console.log("projecttype ", projectType);
  // Load column value according to the Project type
  useEffect(() => {
    if (type === "Custom Schema") {
      const fetchCustomColumns = async () => {
        setLoadingColumns(true); // Start loading state
        try {
          const customSchema = await getCustomSchema();
  
          const groupedColumns = customSchema.map((schema) => {
            return schema.label || schema.schema_name || "Unnamed Schema";
          });
          console.log("customColumns", groupedColumns)
          
          setCustomSchemas(customSchema);
          setColumnsOptions(groupedColumns); // Set options as schema labels
        } catch (error) {
          console.error("Error fetching custom schemas:", error);
          message.error("Failed to load custom schemas.");
        } finally {
          setLoadingColumns(false); // End loading state
        }
      };
  
      fetchCustomColumns();
    } else {
      setColumnsOptions([]); // Clear columns for other types
    }
  }, [type]); // Re-run when 'type' changes
  

  useEffect(() => {
    if (projectType === 'omr') {
      setColumnsOptions(['first_name', 'last_name', 'middle_name']);
    } else {
      setColumnsOptions(['sn', 'name', 'mobile_number']);
    }
  }, [projectType]);

  useEffect(() => {
    // Update the form's columns field when columnsOptions changes
    form.setFieldsValue({
      columns: columnsOptions,
    });
  }, [columnsOptions, form]);

  useEffect(() => {
    if (isVisible) {
      setType("");
      setOmrSubType(""); // Reset OMR sub-type when modal is opened
      setColumnsOptions([]);
      form.resetFields();
    }
  }, [isVisible, form]);
  
  useEffect(() => {
    if (["omr/50", "omr/100", "omr/200"].includes(omrSubType)) {
      setColumnsOptions(['first_name', 'middle_name', 'last_name']);
    } else if (type === "table" || type === "receipt" || type === "book_notes") {
      setColumnsOptions(['sn', 'name', 'mobile_number']);
    } else {
      setColumnsOptions([]);
    }
  }, [type, omrSubType]);


  useEffect(() => {
    if (["omr/50", "omr/100", "omr/200",].includes(omrSubType)) {
      form.setFieldsValue({
        description: "Image contains bubble OMR sheet with first_name, middle_name and last_name.",
      })
    } else if (type === "table" || type === "receipt" || type === "book_notes") {
      form.setFieldsValue({
        description: "Image contains table of names of students with their mobile number.",
      })
    } else {
      form.setFieldsValue({
        description: "",
      })
    }
  }, [type, omrSubType, form]);

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
    setOverallProgress(0);
  };

  const handlePreview = async (file) => {
    setPreviewImage(file.thumbUrl || file.url);
    setPreviewVisible(true);
  };

  const handleSubmit = async (values) => {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    setOverallProgress(0);

    const formData = new FormData();
    formData.append('name', values.name);

    // If the selected type is 'omr', use the omr_sub_type as the type value
    const typeToSubmit = type === 'omr' ? omrSubType : type;
    formData.append('type', typeToSubmit);

    formData.append('description', values.description);
    formData.append('columns', values.columns.join(','));

    fileList.forEach((file) => {
      formData.append('files', file.originFileObj);
    });

    try {
      const response = await createJobWithImages(projectId, formData, (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.round((loaded / total) * 100);
        setOverallProgress(percent);
      });
      console.log("responser",response.job)
      message.success('Job created successfully!');
      form.resetFields();
      setFileList([]);
      setOverallProgress(0);
      // try{
      //   onFinish(response?.job);
      // }catch(error){
      //   console.log("error in on Finish",error)
      // }
      onCancel();
      console.log("resp",response)
      if (response.msg == "Job created successfully"){
        // onFinish()
        window.location.reload(); // Reload the page
        onFinish(projectId)
      }
    } catch (error) {
      console.error('Error creating job:', error);
      message.error('Failed to create job');
      setOverallProgress(0);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      columns: columnsOptions,
    });
  }, [columnsOptions, form]);

  return (
    <Modal
      title="Create a New Job"
      visible={isVisible}
      onCancel={() => {
        onCancel();
        setOverallProgress(0);
        setFileList([]);
        form.resetFields();
      }}
      footer={null}
      width="80%"
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          name: currentDateTime,
        }}
      >
        <Form.Item
          label={<span><ProjectOutlined /> Job Name</span>}
          name="name"
          rules={[{ required: true, message: 'Please enter the job name' }]}
        >
          <Input placeholder="Enter job name" />
        </Form.Item>

        <Form.Item
          label={<span><ProfileOutlined /> Job Type</span>}
          name="type"
          rules={[{ required: true, message: 'Please select the job type' }]}
        >
          <Select
            placeholder="Select job type"
            onChange={(value) => {
              setType(value);
              setOmrSubType(""); // Reset OMR sub-type when changing main type
              form.setFieldsValue({ 
                omr_sub_type: ''
               });
            }}
            value={type}
            style={{ width: '100%' }}
          >
            {jobOptions.map((option) =>
              option.subOptions ? (
                <Option key={option.value} value={option.value}>
                  {option.icon} {option.label}
                </Option>
              ) : (
                <Option key={option.value} value={option.value}>
                  {option.icon} {option.label}
                </Option>
              )
            )}
          </Select>
        </Form.Item>

        {type === 'omr' && (
          <Form.Item
            label={<span><ProfileOutlined /> OMR Sub-Type</span>}
            name="omr_sub_type"
            rules={[{ required: true, message: 'Please select the OMR sub-type' }]}
          >
            <Select
              placeholder="Select OMR sub-type"
              onChange={(value) => setOmrSubType(value)}
              value={omrSubType}
              style={{ width: '100%' }}
            >
              {jobOptions.find(option => option.value === 'omr').subOptions.map((subOption) => (
                <Option key={subOption.value} value={subOption.value}>
                  {subOption.icon} {subOption.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label={<span><FileTextOutlined /> Description</span>}
          name="description"
          rules={[{ required: true, message: 'Please enter the job description' }]}
        >
          <Input.TextArea placeholder="Enter job description" />
        </Form.Item>

        <Form.Item
          label={<span><TagsOutlined /> Attributes/Columns</span>}
          name="columns"
          rules={[{ required: true, message: 'Please specify the columns (tags)' }]}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Enter column names for the batch (e.g., Column1, Column2)"
            loading={loadingColumns}
            // placeholder={

            //     loadingColumns
            //       ? 'Loading columns...'
            //       : 'Enter or select column names for the batch (e.g., Column1, Column2)'
            // }
            tokenSeparators={[',']}
            notFoundContent={loadingColumns ? 'Loading columns...' : 'No options available'}
          >
            {/* {columnsOptions.map((col) => (
              <Option key={col} value={col}>{col}</Option>
            ))} */}
            {columnsOptions.length > 0 ? (
              columnsOptions.map((group, idx) => {
                // Ensure group is an array and join the labels if it is
                const groupLabels = Array.isArray(group) ? group.join(', ') : group;
                
                return (
                  <Option key={idx} value={groupLabels}>
                    {groupLabels}  {/* Display the grouped labels as a comma-separated string */}
                  </Option>
                );
              })
            ) : (
              <Option disabled>No columns available</Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          label={<span><UploadOutlined /> Upload Images</span>}
          rules={[{ required: true, message: 'Please upload images for the job' }]}
        >
          <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={handleFileChange}
            onPreview={handlePreview}
            beforeUpload={() => false}
            multiple
          >
            {fileList.length < 50 && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Select Images</div>
              </div>
            )}
          </Upload>
        </Form.Item>

        {fileList.length > 0 && (
          <div style={{ marginBottom: '16px' }}>
            <Progress
              percent={overallProgress}
              status={overallProgress === 100 ? 'success' : 'active'}
            />
          </div>
        )}

        {/* Submit Button */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading || submitting}
            icon={<PlusOutlined />}
          >
            Create Job
          </Button>
        </Form.Item>
      </Form>

      {/* Image Preview Modal */}
      <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
        <Image alt="Preview" src={previewImage} style={{ width: '100%' }} />
      </Modal>
    </Modal>
  );
};

// CreateJobModal.propTypes = {
//   projectId: PropTypes.string.isRequired,
//   isVisible: PropTypes.bool.isRequired,
//   onCancel: PropTypes.func.isRequired,
//   onFinish: PropTypes.func.isRequired,
//   loading: PropTypes.bool,
// };

export default CreateJobModal;


