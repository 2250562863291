import React, { useState } from "react"
import { Card, Row, Col, Button, Spin, Alert, Typography, Space, Modal, message } from "antd"
import { DeleteOutlined, PlusOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons"
import { useGetAllCustomSchema, deleteCustomSchema, updateCustomSchema, saveCustomSchema } from "../hooks/customSchema"
import CreateCustomSchema from "../components/CustomSchema"

const { Title, Text } = Typography

const CustomSchemaPage = () => {
  const { data, loading, error, refetch } = useGetAllCustomSchema()
  const [selectedEntity, setSelectedEntity] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  if (error) {
    return <Alert message={error} type="error" showIcon />
  }

  const handleAddSchemaClick = () => {
    setIsModalVisible(true)
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
  }

  const handleDeleteSchema = async (id) => {
    try {
      await deleteCustomSchema(id)
      message.success("Schema deleted successfully")
      if (selectedEntity?._id === id) {
        setSelectedEntity(null)
        setIsEditing(false)
      }
      refetch() // Refresh the schema list
    } catch (error) {
      message.error("Failed to delete schema")
      console.error("Error deleting schema:", error)
    }
  }

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleSaveClick = async (updatedSchema) => {
    console.log("updatedSchema", updatedSchema)
    try {
      await updateCustomSchema(selectedEntity._id, updatedSchema)
      message.success("Schema updated successfully")
      setIsEditing(false)
      // Refresh the selected entity with the updated data
      setSelectedEntity((prevEntity) => ({
        ...prevEntity,
        ...updatedSchema,
      }))
      refetch() // Refresh the schema list
    } catch (error) {
      message.error("Failed to update schema")
      console.error("Error updating schema:", error)
    }
  }

  const handleSaveNewSchema = async (newSchema) => {
    try {
      await saveCustomSchema(newSchema)

      message.success("New schema created successfully")
      handleModalClose()
      refetch() // Refresh the schema list
    } catch (error) {
      if (error.response?.data?.message) {
        message.error(error.response.data.message)
      }
      message.error("Failed to create new schema")
      console.error("Error creating new schema:", error)
    }
  }

  return (
    <div style={{ padding: "24px" }}>
      <Row gutter={24}>
        {/* Left Panel */}
        <Col xs={24} md={6}>
          <Card
            title={
              <Row justify="space-between" align="middle">
                <Title level={4} style={{ margin: 0 }}>
                  Available Schema
                </Title>
                <Button type="primary" icon={<PlusOutlined />} onClick={handleAddSchemaClick}>
                  Add Schema
                </Button>
              </Row>
            }
          >
            {loading ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <Spin size="large" />
              </div>
            ) : (
              <Space direction="vertical" style={{ width: "100%" }}>
                {Array.isArray(data) && data.length >0 ? (
                 data.map((entity) => (
                  <Card
                    key={entity._id}
                    style={{
                      cursor: "pointer",
                      backgroundColor: selectedEntity?._id === entity._id ? "#f0f0f0" : "white",
                    }}
                    bodyStyle={{ padding: "12px" }}
                    onClick={() => {
                      setSelectedEntity(entity)
                      setIsEditing(false)
                    }}
                  >
                    <Row justify="space-between" align="middle">
                      <Col>
                        <Text strong>{entity.schema_name}</Text>
                        <br />
                        <Text type="secondary" style={{ fontSize: "12px" }}>
                          ID: {entity._id}
                        </Text>
                      </Col>
                      <Col>
                        <Button
                          type="text"
                          danger
                          icon={<DeleteOutlined />}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleDeleteSchema(entity._id)
                          }}
                        />
                      </Col>
                    </Row>
                  </Card>
                ))
                ):(
                  <div style={{ textAlign: "center", padding: "20px" }}> 
                  No schema avaialble </div>
                  )}
              </Space>
            )}
          </Card>
        </Col>

        {/* Right Panel */}
        <Col xs={24} md={18}>
          <Card
            style={{ height: "100%", minHeight: "400px" }}
            // extra={
            //   selectedEntity && (
            //     <Button
            //       type="primary"
            //       icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
            //       onClick={isEditing ? () => handleSaveClick(selectedEntity) : handleEditClick}
            //     >
            //       {isEditing ? "Save" : "Edit"}
            //     </Button>
            //   )
            // }
            extra={selectedEntity && !isEditing && (
              <Button type="primary" icon={<EditOutlined />} onClick={handleEditClick}>
                Edit
              </Button>
            )}
            
          >
            {selectedEntity ? (
              <CreateCustomSchema
                initialSchema={selectedEntity}
                readOnly={!isEditing}
                onSave={handleSaveClick}
                isNewSchema={false}
              />
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#999",
                }}
              >
                No entity selected. Click on an entity to view its details here.
              </div>
            )}
          </Card>
        </Col>
      </Row>

      {/* Modal for Create Schema */}
      <Modal
        title="Create Custom Schema"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width="80%"
        style={{ top: "20px" }}
      >
        <CreateCustomSchema onSave={handleSaveNewSchema} isNewSchema={true} />
      </Modal>
    </div>
  )
}

export default CustomSchemaPage

